.root {}

.root.empty {}

.root.success {}

.root.error {}

.bagCount {
    @apply absolute text-center;
    top: -6px;
    left: 4px;
    right: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    background: #39bd97;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
}

.slidebarPopupHeader {
    @apply flex relative items-center;
    height: 94px;
    border-bottom: #eee solid 1px;
}

.cartIcon {
    @apply flex relative left-0 float-left w-auto;
    margin: 6px 0 0 20px;
    right: initial;
    height: 37px;
}

.totalPrice {
    @apply absolute w-auto flex bg-transparent;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    right: initial;
    padding: 0 15px;
    color: #393972;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
}

.closeButtonArea {
    @apply bg-white absolute text-center;
    font-size: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba(97, 97, 97, 0.50);
    margin: 0 0 2px;
    right: 20px;
    line-height: 58px;
    font-weight: 300;
}

.popupSidebarDetail {
    @apply text-center;
    padding: 15px 15px 0px 15px;
    max-height: calc(100vh - 215px);
    overflow: auto;
    overflow-y: auto;
}

.popupSidebarDetail .productHeading {
    @apply relative;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    color: #262664;
    margin: 0px 0px 15px 0px;
}

.popupSidebarDetail .productHeading+div {
    font-size: 16px;
    line-height: 22px;
    color: #262664;
    margin-bottom: 25px;
}

.opupContentArea .productName {
    @apply block;
    font-size: 18px;
    line-height: 23px;
    color: #393971;
    margin: 10px 0px;
}

.opupContentArea .productPrice {
    font-weight: 700;
    font-size: 17px;
    line-height: 19px;
    margin-bottom: 20px;
    color: #393971;
    padding: 0px;
}

.productButtons .productBtn {
    @apply flex m-auto uppercase justify-center items-center cursor-pointer;
    background: #FD566F;
    border-radius: 5px;
    max-width: 260px;
    color: #fff;
    font-size: 15px;
    flex-flow: row wrap;
    min-height: 42px;
    line-height: normal;
    padding: 4px 10px 0 10px;
}

.productButtons .productBtn:hover {
    opacity: 0.9;
}

.productButtons .noThanksBtn {
    @apply flex justify-center items-center cursor-pointer w-full;
    border: 1px solid #FD566F;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 260px;
    text-transform: uppercase;
    margin: 10px auto 0 auto;
    font-size: 15px;
    flex-flow: row wrap;
    min-height: 42px;
    line-height: normal;
    padding: 1px 10px 0 10px;
    color: #FD566F;
    font-weight: 700;
}

.productButtons .noThanksBtn:hover {
    opacity: 0.9;
}

.checkoutrow {
    @apply absolute w-full text-center;
    bottom: 20px;
    left: 0;
    padding: 0 15px;
}

.checkoutBtnIcon {
    @apply absolute inline-block align-middle bg-no-repeat bg-contain m-auto;
    width: 35px;
    height: 35px;
    background-image: url(/cart/checkout-arrow.png);
    right: 20px;
    top: 0;
    bottom: 0;
}

.checkoutBtn {
    @apply block relative;
    min-height: 59px;
    background: #39bd97;
    border-radius: 50px;
    padding: 22px 37px 18px 0;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.78px;
    color: #fff;
    outline: none !important;
    border: none !important;
}

.checkoutBtn:hover {
    background: #39bd97;
    color: #fff;
    opacity: 0.9;
}