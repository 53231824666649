.root {
  @apply inline-flex items-center justify-center px-10 py-4 font-semibold leading-6 text-center uppercase transition duration-150 ease-in-out border border-transparent rounded-sm shadow-sm cursor-pointer bg-core-rose text-accents-1;
}

.root:hover {
  @apply border bg-accents-0 text-primary border-secondary;
}

.root:focus {
  @apply outline-none shadow-outline-normal;
}

.root[data-active] {
  @apply bg-gray-600;
}
.loadingIcon {
  @apply relative inline-block ml-4;
  top: -3px;
}
.loading {
  @apply cursor-not-allowed bg-accents-1 text-accents-3 border-accents-2 opacity-80;
}

.slim {
  @apply py-2 normal-case transform-none;
}

.disabled,
.disabled:hover {
  @apply cursor-not-allowed text-accents-4 border-accents-2 bg-accents-1;
  filter: grayscale(1);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}
